<template>
  <lf-modal :title="modalTitle" :close="close">
    <template v-slot:type>
      <div
        v-if="activeOffer?.offer_product_type"
        class="rounded bg-gray-100 px-2 py-1-5"
      >
        {{ activeOffer.offer_product_type }}
      </div>
    </template>
    <template v-slot:content>
      <div class="flex-col p-6 overflow-y-auto max-h-110 space-y-5 pt-3">
        <div v-if="Array.isArray(offer) && offer.length > 1" class="flex">
          <lf-tab
            v-for="(data, index) in offer"
            :key="data.id"
            :active="data.id === activeOffer?.id"
            @click="setActiveOffer(data)"
          >
            {{ `${$t("COMMON.OFFER")} ${index + 1}` }}
          </lf-tab>
        </div>
        <div class="flex-1 max-w-4xl mb-6 space-y-5">
          <div
            v-for="(group, groupKey) in fields"
            :key="groupKey"
            class="flex flex-col mb-3"
          >
            <lf-h4>
              {{ wordsFirstLetterToUpper(`${groupKey}`, "_") }}
            </lf-h4>
            <div
              class="px-10 md:px-0 grid grid-cols-2 grid-flow-row gap-x-3 mt-6 gap-y-5"
            >
              <product-read-only-field
                v-for="(rules, name) in group"
                :key="name"
                :value="getValue(name)"
                :productTypeId="productTypeId || -1"
                :name="`${name}`"
                :rules="rules"
                role="read-only-field"
                :initialPayment="initialPayment"
                :section="section"
                :class="{
                  'sm:h-23 sm:pt-12 xm:h-18 xm:pt-6 xl:h-11-5 xl:pt-0':
                    String(name) === 'total_initial_payment'
                }"
                :currency="activeOffer?.currency"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="p-6 flex justify-end border-t space-x-4">
        <outline-button @click="close">
          {{ $t("COMMON.CLOSE") }}
        </outline-button>
      </div>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import {
  removeCurrencyPrefix,
  wordsFirstLetterToUpper
} from "@/helpers/formatting";
import ProductReadOnlyField from "../components/ProductReadOnlyField.vue";
import { useStore } from "vuex";
import type { PropType } from "vue";
import { computed, ref } from "vue";
import type { IOffer, IOfferFundingData } from "@/models/funders";
import type { FieldsFromOptionsCallback } from "@/models/common";
import { FUNDING_SECTION, OFFER_SECTION } from "@/helpers/constants";

const props = defineProps({
  close: {
    type: Function as PropType<() => void>,
    required: true
  },
  offer: {
    type: Object as PropType<
      IOffer | (IOffer & IOfferFundingData) | null | IOffer[]
    >
  },
  productType: {
    type: String,
    required: false
  },
  modalTitle: {
    type: String,
    default: "Offer Details"
  },
  fundedInfo: {
    type: Boolean as PropType<boolean | null>,
    default: false
  }
});
const { getters } = useStore();

const activeOffer = ref(
  Array.isArray(props.offer) ? props.offer[0] : props.offer
);
const productTypeId = computed(() => activeOffer.value?.product?.id);
const initialPayment = computed(
  () =>
    removeCurrencyPrefix(activeOffer.value?.advance_payments) +
    removeCurrencyPrefix(activeOffer.value?.fee)
);
const section = props.fundedInfo ? FUNDING_SECTION : OFFER_SECTION;

const fields = computed(() => {
  const getFields = getters[
    props.fundedInfo ? "options/productFundingFields" : "options/productFields"
  ] as FieldsFromOptionsCallback;
  return getFields(activeOffer.value?.product?.id);
});

const setActiveOffer = (offer: IOffer) => {
  activeOffer.value = offer;
};

const getValue = (name: number | string) => {
  const val = activeOffer.value?.[name as keyof typeof activeOffer.value];
  return (val ?? undefined) as string | number | undefined;
};
</script>
